import React, { useContext } from 'react';
import ActiveLink from './ActiveLink';
import notificationTypes from '../constants/modalNotificationConst';

import appContext from '../Context/appContext';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';

const renderASAPifNeeded = () => {
  return (
    <span className="flex-item">
      &nbsp;
      <span className="icon-home" />
      &nbsp;
    </span>
  );
};

const AddressBox = ({ children, showAdressOnSmall, className }) => {
  // const {
  //   state: {
  //     user: { deliveryDetails: { address, deliveryTypeSelected } = {} }
  //   }
  // } = useContext(appContext);
  const { deliveryDetails: { address, deliveryTypeSelected } } = useSelector(state => state.user)
  const dispatchRedux = useDispatch()

  function openAddressPopup() {
    dispatchRedux(pushNotification({ type: notificationTypes.deliveryAddressDetail }))
  }

  return (
    <div className={` address-box ${!showAdressOnSmall ? 'd-none' : ''} d-lg-block ${className}`}>
      <ActiveLink className="has-box-shadow" onClick={openAddressPopup}>
        {renderASAPifNeeded(deliveryTypeSelected)}
        <span className="flex-item adddress">{address}</span>
        <span className="flex-item text-center icon-down" />
      </ActiveLink>
      {children}
    </div>
  );
};

export default AddressBox;
