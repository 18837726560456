import { useRouter } from 'next/router';
import Slider from 'react-slick';
import { PRIMARY } from '../constants/bannerTypes';
import { isNewHomePageApplicable } from '../constants/feature';
import getDefaultTab from '../helpers/getDefaultTab';
import { useCommonFunctions } from '../hooks/common';
import { BannerImage } from './BannerNew';
import DangerousHTML from './DangerousHTML';
import React from 'react'

export const BannerSlider = React.memo(({ bannerData: receivedBannerData, customBanner: receivedCustomBannerData = {} }) => {
  //not done for unified menu yet

  const router = useRouter();
  const {
    query: { tab }
  } = router;

  const { redirectToLink } = useCommonFunctions();

  let currentTab = tab || getDefaultTab();

  const asapBanner =
    receivedBannerData &&
      receivedBannerData[currentTab] &&
      receivedBannerData[currentTab].primary &&
      receivedBannerData[currentTab].primary.length
      ? receivedBannerData[currentTab]
      : {};

  const customBanner =
    receivedCustomBannerData &&
      receivedCustomBannerData[currentTab] &&
      receivedCustomBannerData[currentTab].primary &&
      receivedCustomBannerData[currentTab].primary.length &&
      receivedCustomBannerData[currentTab].primary[0].desktop_image_url
      ? receivedCustomBannerData[currentTab]
      : {};
  const bannerData = customBanner.primary || asapBanner.primary || [];
  const secondaryBannerData = customBanner.secondary || asapBanner.secondary || [];
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: bannerData.length > 1 ? 2 : 1,
    slidesToScroll: bannerData.length > 1 ? 2 : 1,
    mobileFirst: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };
  return (
    <div
      className={`banner relative container banner-control-${bannerData.length} default-site-banner  ${isNewHomePageApplicable ? 'slider-wrap' : 'marketing-banner'
        } ${!secondaryBannerData.length && 'no-secondary-banner'}`}
    >
      <Slider {...settings}>
        {bannerData.map((bannerItem, i) => (
          <div id={bannerItem.id} key={bannerItem.id} className="banner-inner relative ">
            <BannerImage bannerItem={bannerItem} secondaryBannerData={secondaryBannerData} />
            <div className="container-fluid  just-a-container ">
              <div className="banner-content">
                {bannerItem.header && (
                  <h1 className="">
                    <DangerousHTML html={bannerItem.header} className="mb-0" />
                  </h1>
                )}
                {bannerItem.sub_header ? (
                  <h2 className="">
                    <DangerousHTML html={bannerItem.sub_header} className="mb-0" />{' '}
                  </h2>
                ) : null}

                {bannerItem.paragraph && (
                  <div className=" half-rem-mt  b-description white-color">
                    <DangerousHTML html={bannerItem.paragraph} className="mb-0" />
                  </div>
                )}
                {bannerItem.button_text && (
                  <a onClick={() => redirectToLink(bannerItem, i, PRIMARY)} className="click-capture-tag" />
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
});
