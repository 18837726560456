import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import notificationTypes from '../constants/modalNotificationConst';
import appContext from '../Context/appContext';
import { pushNotification } from '../redux/slices/modalSlice';

function HraderDeliveryAddress({ hideOnLarge, className }) {
  // const {
  //   // state: { user: { deliveryDetails } = {} },
  //   dispatch
  // } = useContext(appContext);
  const deliveryDetails = useSelector(state => state.user.deliveryDetails)
  const dispatchRedux = useDispatch()

  const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

  function openAddressPopup() {
    // dispatch({ type: 'pushNotification', payload: { type: notificationTypes.deliveryAddressDetail } });
    dispatchRedux(pushNotification({ type: notificationTypes.deliveryAddressDetail }))
  }

  const renderAddress = () => {
    const { address } = deliveryDetails;
    if (address) {
      return address;
    }
    return 'Set Delivery Address';
  };

  return (
    <div
      className={`set-delivery-address-btn ${hideOnLarge ? 'd-lg-none' : ''} ${className}  ${isCheckoutOnly ? 'set-delivery-c-only' : ''
        }`}
      role="presentation"
      onClick={openAddressPopup}
    >
      <span className="">
        <i className={'icon-pin'} />
        {renderAddress()}
        <i className="icon-chevron-right-e d-icon d-none" />
      </span>
    </div>
  );
}

export default HraderDeliveryAddress;
