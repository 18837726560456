export const matchFilter = (localFilters, newFilters) => {
  if (!localFilters || localFilters.length === 0) return newFilters;
  const filterList = newFilters.map((filter, index) => {
    const values = filter.values.map(ele => {
      const checked =
        (localFilters &&
          localFilters?.length &&
          localFilters[index]?.values?.find(localele => localele.value === ele.value)?.checked) ||
        false;
      return { ...ele, checked };
    });
    return { ...filter, values };
  });
  return filterList;
};
