import React from 'react';
import dynamic from 'next/dynamic';

const GrassdoorCategoryMenu = dynamic(() => import('./grassdoor/CategoryMenu'));


const CommonCategoryMenu = React.memo((props) => {
  return <GrassdoorCategoryMenu {...props} />;
});

export default CommonCategoryMenu;
